* {
    font-family: 'Poppins';
    font-style: normal;
    /* color: #3D3D3D; */
}

.tabContainer {
    margin: 30px 36px;
    padding: 15px 30px;
    background: #FFFFFF;
    border: 1px solid #E6E6E6;

    @media(max-width: 768px) {
        padding: 20px;
        margin: 24px;
    }
}

.header {
    margin-bottom: 39px;
    display: flex;
    justify-content: space-between;

    @media(max-width: 768px) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
}

.titleContainer {
    display: flex;
    align-items: baseline;
    column-gap: 24px;

    @media(max-width: 768px) {
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    & h2 {
        margin: 0;
        font-weight: 600;
        font-size: 24px;
        line-height: 56px;
        letter-spacing: -0.9375px;
        color: var(--dark-grey, #3D3D3D);

        @media(max-width: 768px) {
            font-size: 16px;
            line-height: normal;
        }
    }

    & p {
        margin: 0;
        font-weight: 400;
        font-size: 18px;
        letter-spacing: -0.0888889px;
        color: var(--dark-grey, #3D3D3D);

        @media(max-width: 768px) {
            font-size: 14px;
            line-height: normal;
        }
    }
}

.closeButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;

    & span {
        font-weight: 700;
        font-size: 12px;
        line-height: 30px;
        letter-spacing: 1.5px;
    }

    & svg {
        fill: black;
        scale: 1.2;
        margin-left: 7px;
    }
}

.wrapperContainer {
    display: flex;
    position: relative;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    @media(max-width: 1400px) {
        align-items: flex-start;
        flex-direction: column;
        gap: 20px;
    }
}

.experts {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;

    @media(max-width: 1200px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }

    @media(max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 0;
    }
}

.expertListing {
    position: relative;
    display: flex;
    align-items: center;
    border-right: 1px solid #E6E6E6;
    margin-right: 57px;
    padding-right: 25px;
    gap: 12px;

    @media(max-width: 1400px) {
        margin-right: 20px;
    }

    @media(max-width: 1200px) {
        border: 1px solid #E6E6E6;
        padding: 10px;
        margin-right: 0;
    }

    @media(max-width: 768px) {
        border: none;
        padding: 0;
        border-bottom: 1px solid #E6E6E6;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }

    & h3 {
        margin: 0;
        width: fit-content;
        color: var(--dark-grey, #3D3D3D);
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.089px;

        @media(max-width: 768px) {
            font-size: 12px;
            line-height: normal;
        }
    }

    & a {
        width: 70px;
        height: 70px;
        min-width: 70px;
        /* margin-right: 12px; */

        @media(max-width: 768px) {
            width: 40px;
            height: 40px;
            min-width: 40px;
        }

        & span {
            font-size: 12px !important;
        }
    }

    & img {
        width: 70px;
        height: 70px;
        min-width: 70px;
        /* margin-right: 12px; */

        @media(max-width: 768px) {
            /* width: 40px;
            height: 40px;
            min-width: 40px; */
        }
    }

    & .crossSign {
        top: 0px;
        right: 5px;
        width: 14px;
        height: 14px;
        cursor: pointer;
        position: absolute;

        @media(max-width: 768px) {
            position: absolute;
            right: 0;
            top: 34%;
            transform: translate(-50%, -50%);
            width: 20px;
            height: 20px;
        }

        & svg {
            @media(max-width: 768px) {
                width: 20px;
                height: 20px;
            }
        }
    }
}

.bottomButton {
    display: flex;
    gap: 20px;

    @media(max-width: 500px) {
        gap: 10px;
        flex-wrap: wrap;
        width: 100%;
    }
}

.addExpertButton {
    min-width: 174px !important;
    height: 44px;
    margin-right: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.267px;

    @media(max-width: 768px) {
        width: 183px;
        height: 35px;
        min-height: 35px;
        margin: auto;
    }

    @media(max-width: 500px) {
        width: 100%;
    }
}

.compareButton {
    width: 136px;
    height: 44px;
    min-height: 44px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.267px;

    @media(max-width: 768px) {
        width: 183px;
        height: 35px;
        min-height: 35px;
        margin: auto;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
    }

    @media(max-width: 500px) {
        width: 100%;
    }
}